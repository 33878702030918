const initialState = {
    refreshStatus: false,
    reportCount: 0
}

const REFRESH_REPORT = (state = initialState, {type, payload}) => {
    switch(type) {
        case "REFRESH": {
            return {
                ...state,
                refreshStatus: !state.refreshStatus
            };
        }
        case "UPDATE_RCOUNT": {
            return {
                ...state,
                reportCount : payload.reportCount
            }
        }
        case "EMPTY_REPORTS": {
            return {
                ...state,
                reportCount: 0
            }
        }
        default:
            return state;
    }
}

export default REFRESH_REPORT;