import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { icons } from "./assets/icons";
import { Provider } from "react-redux";
import getStore from "./store";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css"
import { PersistGate } from "redux-persist/integration/react";

const { store, persistor } = getStore;

React.icons = icons;

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
        <ReactNotification/>
        <App />
     </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
