import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { getToken } from "./helpers/firebase";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const VerifyOtp = React.lazy(() => import("./views/pages/verifyOtp/verifyOtp"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ForgotPassword = React.lazy(() =>
  import("./views/forgotPassword/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/resetPassword/resetPassword")
);

// views
const ValidateEmail = React.lazy(() =>
  import("./views/accountSettings/verifiedEmail")
);
const CheckEmailAccount = React.lazy(() =>
  import("./views/accountSettings/checkEmail")
);

class App extends Component {
  render() {
    getToken();
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/verifyOtp/:token"
              name="Verify Otp"
              render={(props) => <VerifyOtp {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/reset-password/:token"
              name="Reset Password Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/checkEmail"
              name="Check email"
              render={(props) => <CheckEmailAccount {...props} />}
            />

            <Route
              path="/verified/:id"
              name="Email Verified"
              render={(props) => <ValidateEmail {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />

            <Route
              path="*"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;