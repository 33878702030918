import { combineReducers } from "redux";
import ADMIN_REDUCER from "./admin";
import CHAT_FILE from "./chat";
import REFRESH_REPORT from "./refreshReport";

const initialState = {
  sidebarShow: "responsive",
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};
const rootReducer = combineReducers({
  ADMIN_REDUCER,
  CHAT_FILE,
  REFRESH_REPORT,
  nav: changeState,
});

export default rootReducer;