const initialState = {
  adminData: {},
  isAuth: false,
  permissionData: [],
};

const ADMIN_REDUCER = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LOGIN": {
      return {
        ...state,
        adminData: payload,
      };
    }
    case "OTPVERIFIED": {
      return {
        ...state,
        adminData: payload,
        isAuth: true
      }
    }
    case "LOGOUT": {
      return initialState;
    }
    case "DASHBOARD_DATA": {
      return {
        ...state,
        dashboardData: payload,
      };
    }

    case "ADDPERMISSIONS": {
      return {
        ...state,
        permissionData: payload,
      };
    }
    default:
      return state;
  }
};

export default ADMIN_REDUCER;